.list-proyectos {

    .ant-checkbox-wrapper {
        margin-left: 0 !important;

        & > span:not(.ant-checkbox){
            width: 100%;
            max-width: 300px;
            
        }
    }

}