.menu-propiedades {
    
    background-color: transparent !important;
    margin: 1em 0 !important;
    width: 100%;
    max-width: calc(100% - 172px) !important;
    display: inline-block !important;

    &.ant-menu-horizontal  {
        border-bottom: none !important;
    }
}


.tabs-propiedades{
    border-bottom: none !important;   
}

.clientes{
    .collapse-list .ant-collapse-header{
        background-color: #FFF !important;
    }
    
    .collapse-list .ant-collapse-extra .ant-typography{
        color: #000 !important;
    }
    
    .collapse-list .ant-collapse-content-box{
        // background-color:  !important;
        border-radius: 0 0 6px 6px !important;
        box-shadow: none !important;
        border-top: 1px solid #E9E9E9;
    }
    
    .ant-collapse-item-active.collapse-list .ant-collapse-header{
        border-radius: 6px 6px 0 0 !important;
    }
}



.propiedades { 
    &.component-list .component-list-item .ant-card-body{
        padding: 13px !important;
    }

    &.component-list .ant-list-pagination{
      margin-bottom: 0;  
    }

    &-adicionales .ant-form-item{
        margin-bottom: 0px !important;
    }
}

.detalle-propiedad{
    .ant-spin-nested-loading{
        width: 100%;
    }
}