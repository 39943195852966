.text-warning{
    color: #faad14
}

.text-error{
    color: #ff5456
}

.text-success{
    color: #52c41a
}