.tipo-1 {
    background-color: #00EE0A !important;
}

.tipo-2 {
    background-color: #F00D0D !important;
}

.titulo {
    
    font-size: 25px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.018em;
    text-align: left;

}

.tag-inmueble{
    border-radius: 15px !important;
    background: rgba($color: #00DC3E, $alpha: 0.5) !important;
    color: #00780C  !important;
}