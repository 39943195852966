.tag-categ {
    border: unset !important;
    background-color: #54B6BA !important;
    color: #FFF !important;
}

.tag-subcateg {
    border: unset !important;
    background-color: #38A1DA !important;
    color: #FFF !important;
}

.card-title {
    margin: unset !important;

    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.018em;
    text-align: left;

}

.sub-title {
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: -0.018em;
    text-align: left;
    color: #858997 !important;

}

.title {

    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.018em;
    text-align: left;
    color: #0047FF !important;

}

.proveedor {
    
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.018em;
    text-align: left;
    color: #858997 !important;
    
}

.mejor {

    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.018em;
    text-align: left;
    color: #00EE0A !important;
}

.peor {

    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.018em;
    text-align: left;
    color: #F00D0D !important;
}

.precios {

    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.018em;
    text-align: left;
    color: #858997 !important;
}

.card-producto {
    min-height: 280px !important;
}