.uploader-plano{
    float: right;
}


.image-mapper-container{
    position: relative;

    .button-mapper-control{
        position: absolute;
        right: 1em;
        z-index: 1;

        transition: all 1s ;

    }

    .button-mapper-close{
        top: calc(1em);
    }

    .button-mapper-delete{
        top: calc(1.5em + 32px);
    }

    .button-mapper-rollback{
        top: calc(2em + 64px);
    }


}


