$padding: 12px;
$radius: 6px;
$color: #333;
$color-light: white;
$primary-color: #3498db;
$primary-color-light: lighten($primary-color, 40%);
$bg-color-light: #DDD;

$message-min-width: 75px;
$message-max-width: 80%;
$message-line-height: 20px;

.chat {
    border-radius: $radius;
    position: relative;
    flex-direction: row;
    height: 650px;
    

    &-form {
        position: absolute;
        flex-direction: row;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-bottom: 1em;
        
    }

    .messages {
        background-color: $color-light;
        border-radius: $radius;
        overflow: hidden;
        height: 100%;
        flex: 1 1 auto;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        box-shadow: inset 0px 10px 15px -21px rgba(0, 0, 0, 0.5);
        position: relative;
        .messages-history {
            overflow-y: scroll;
            flex: 1 1 auto;
            min-height: 100%;
            max-height: 500px;
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-end;
            box-shadow: inset 0px -10px 15px -21px rgba(0, 0, 0, 0.5);
            border-radius: 4px;

            .message {
                width: 100%;

                padding: $padding;
                padding-top: 0;
                display: flex;

                &:last-child {
                    padding-top: $padding;
                }

                &.me {
                    text-align: right;

                    .message-body {

                        color: $color-light;
                        background-color: $primary-color;
                        margin-left: 0 !important;

                    }
                }

                .message-body {
                    position: relative;
                    margin-left: 5px;
                    background-color: $bg-color-light;
                    color: $color;
                    font-weight: 400;
                    display: inline-block;
                    padding: 14px;
                    border-radius: $radius;
                    max-width: $message-max-width;
                    min-width: $message-min-width;
                    line-height: $message-line-height;
                    text-align: initial;
                }
            }
        }
    }

    .chat-form-file{
        min-height: 50px;
        padding: 8px;
        position: absolute;
        width: 100%;
        background: rgba(255, 255, 255, 0.8);
        bottom: 35px;

        &-card {
            text-align: center;
            height: 112px;
            width: 100px;

            position: relative;

            transition: 0.2s opacity ease-out;

            .hover-content{
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 6px;
                z-index: 100;
                width: 100%;
                height: 100%;
                backdrop-filter: blur(3px);
                display: none;
                transition: 0.5 all ease-in-out;

                .button-delete-file{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%)
                }
            }
            
            &:hover .hover-content {
                display: block !important;
            }
        }
        &-image {
            width: 76px;
            height: 76px;
            // background: red;
            font-size: 40px;

            &-icon {
                padding-top: 10px;
            }
        }


    }

    
}

/*Tamaño del modal*/
.modal-form.modal-detalle-chat{
    max-width: 1400px;
}

/*Bordeado del modal*/
.modal-form.modal-detalle-chat .ant-modal-content{
    border-radius: 22px;
}


.modal-form.modal-detalle-chat .ant-modal-body{
    padding: 0;
    min-height: 400px;
}

/*Columna de texto*/
.modal-form.modal-detalle-chat .col-text{
    padding: 2rem;   
}

.modal-form.modal-detalle-chat .col-text .text-lg{
    font-size: 20px; 
}

.modal-form.modal-detalle-chat .col-text .text{
    font-size: 14px; 
    color: #343434;
}

/*Estilos para los tags de la columa de texto*/
.modal-form.modal-detalle-chat .col-text .tag{
    min-width: 124px;
    height: 34px;
    text-align: center; 
    border-radius: 11px;
    line-height: 33px;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 16px;
}


.modal-form.modal-detalle-chat .col-text .tag.tag-estatus-1{
    background: #B706F5;
}
.modal-form.modal-detalle-chat .col-text .tag.tag-estatus-2{
    background: #efca04;
}
.modal-form.modal-detalle-chat .col-text .tag.tag-estatus-3{
    background: #f57906;
}
.modal-form.modal-detalle-chat .col-text .tag.tag-estatus-0{
    background: #f50606;
}
.modal-form.modal-detalle-chat .col-text .tag.tag-estatus-4{
    background: #6106f5;
}
.modal-form.modal-detalle-chat .col-text .tag.tag-estatus-5{
    background: #f506b2;
}

.modal-form.modal-detalle-chat .col-text .tag.tag-estatus-6{
    background: #08e453;
}



/*Estilos para los titulos de la secciones*/
.modal-form.modal-detalle-chat .col-text .modal-title{
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #343434;
    margin-top: 1rem;
}

/*Estilos para los labels de la columna de texto*/
.modal-form.modal-detalle-chat .col-text .ant-form-item-label label{
    font-weight: 400;
    font-size: 12px;
    color: #696969;
}

/*Estilos para los labels de la columna de texto*/
.modal-form.modal-detalle-chat .col-text .ant-form-item-label{
    margin-bottom: 0px !important;
    padding-bottom: 0px;
}

/*Columa de la parte del chat de modal*/
.modal-form.modal-detalle-chat .chat-side{
   height: 100%;
   border-left: solid 1px gray;
   border-radius: 0 22px 22px 0;
   overflow: hidden;
}

/*titulo de la columna del chat*/
.modal-form.modal-detalle-chat .chat-side .title-chat{
   border-radius: 0 15px 0 0;
   padding: 18px 0;
   border-bottom: solid 1px gray ;
}