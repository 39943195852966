.space-small-vertical {
    line-height: 1.15rem;
    gap: 0 !important;
}

.tag-large {
    line-height: 20px !important;
    padding: 5px !important;
    font-size: 14px !important;
    opacity: 0.4 !important;


}

.custom-progress-bar {
    border-radius: 10px;
}


.custom-progress-bar-green,
.custom-progress-bar-red {
    line-height: 13px;
}

.custom-progress-bar .css-134xjk4,
[data-css-134xjk4] {
    box-shadow: -2px 4px 24px -6px rgb(82 74 74 / 17%) inset !important;
    -webkit-box-shadow: -5px 6px 8px -6px rgb(82 74 74 / 17%) inset !important;
    -moz-box-shadow: -2px 4px 24px -6px rgba(82, 74, 74, 0.17) inset !important;
}

.card-list-item-blue {
    background: rgba(69, 110, 255, 0.05);
}
