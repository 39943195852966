/* SIDER MENU */
/*
 * @component Sidebar 
 * @description Ajusta el width del sidebar para que se ajuste al logo del header
 * Si se mueve este estilo, se tiene que modificar tambien el del header-logo
 */
.sider-width {
    
    max-width: 300px!important;
    width: calc(100vw - ( 100vw * 0.78 - 20px))!important;
}

/*
 * @component Sidebar 
 * @description Ajusta el color de fondo del menu
 */
.ant-menu {
    background: #FFFFFF;
}


/*
 * @component Sidebar 
 * @description Ajusta estilo del titulo de cada grupo de elementos del menu
 */
.ant-menu-item-group-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 11px !important;
    text-transform: uppercase;
    line-height: 13px;
    letter-spacing: 3px;
    color: #C4C4C4;
}
/*
 * @component Sidebar 
 * @description Ajusta el tamaño de la fuente de todo el sider
 */
.custom-sider * {
    font-size: 0.9rem;
}

/*
 * @component Sidebar 
 * @description Ajusta estilo de los iconos de todo el sider
 */
.custom-sider svg {
    font-size: 1rem;
    width: 30px;
}

/*
 * @component Sidebar 
 * @description Ajusta estilo de los iconos de todo el sider
 */
.custom-sider .ant-layout-sider-children ul.ant-menu li:first-child{
    margin-top:0.5rem;
}

/*
 * @component Sidebar  ->MenuDivider
 * @description Añade un espacio entre grupos de elementos del menu del sidebar
 */
.group-sider-divider {
    margin: 2rem 0px;
}
/*
 * @component Sidebar 
 * @description Cambia el color de los links del menu.item del sidebar
 */
.custom-sider ul li.ant-menu-item, .custom-sider ul li.ant-menu-item a {
    color: #4D4D4D;
}


/*
 * @component Sidebar 
 * @description Estilos de fuente de cada titulo del menu
 */
.custom-sider ul li.ant-menu-item, .custom-sider ul div.ant-menu-submenu-title {
    font-weight: 700;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 3px;
    color: #4D4D4D!important;
}


/*
 * @component Sidebar -> Menu
 * @description Cambia la posicion del borde del elemento seleccionado
 */
.custom-sider ul li.ant-menu-item::after {
    left: 0;
    border-left: 5px solid #0047FF;
    border-right: none!important;
}

/*
 * @component Sidebar -> Menu
 * @description Ajusta el margen de los iconos del titulo de cada menu.item
 */
.custom-sider ul li.ant-menu-item svg, .custom-sider ul div.ant-menu-submenu-title svg {
    margin-right: 15px;
}

/*
 * @component Sidebar -> Menu
 * @description Cambia el color del menu.item seleccionado tanto el icono como el texto
 */
.custom-sider ul li.ant-menu-item:hover *, .custom-sider ul li.ant-menu-item:hover a {
    color: #0047FF;
}