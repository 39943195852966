.budgeting .text-gray-lg {
	font-weight: 300;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: -0.018em;
	color: #858997;
	margin-bottom: 0.4rem;
}

.budgeting .text-black-lg {
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: -0.018em;
	color: #000000;
}


.budgeting .text-gray {
	font-weight: 300;
	font-size: 13px;
	line-height: 25px;
	letter-spacing: -0.018em;
	color: #858997;
	margin-bottom: 0.5rem;
}


.budgeting .text-black {
	font-weight: 300;
	font-size: 14px;
	line-height: 25px;
	letter-spacing: -0.018em;
	color: #000000;
	margin-bottom: 0.5rem;
}

.budgeting .row-blue {
	margin: 1rem 0;
	background: #2c77f9;
	box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
	border-radius: 10px;
	min-height: 55px;
	font-size: 18px;
	font-weight: 500;
	padding-left: 1.5rem;
}

.budgeting .row-blue-divider {
	margin: 1rem 0;
	background: transparent;
	border-top: 2px solid #2c77f9;
	min-height: 55px;
	font-size: 18px;
	font-weight: 500;
	padding-left: 1.5rem;
}

.budgeting .row-blue .ant-typography{
	color: white;
}


.budgeting .ant-collapse-header {
	background: white !important;
}

.budgeting .ant-collapse-header .anticon,
.budgeting .ant-collapse-header .ant-typography{
	color: black !important;
}


.progressbar{
	width: 75% !important;
}

.card-proyecto .ant-card-body{
	display: grid;
}

.card-proyecto .ant-progress-text{
	text-align: right;
	font-weight: 700;
	font-size: 18px;
	color: #000000;
	display: inline;
}

.card-proyecto .ant-progress-outer{
	max-width: 96.1%;
}