.almacen-ingreso{
    display: flex;
    margin-bottom: 8px;
    align-items: baseline;
    column-gap: 12px;

    &-almacenes{
        width: 70%;
    }

    &-cantidad{
        & .w-100 {
            width: 100% !important;
        }
    }
}