.PM-card-dashboard .PM-card-title {
    margin-bottom: 0 !important;
}



.statistic-financial{
    position: relative;
    top: 50%;
    transform: translateY(-60%)
}


.statistic-financial.net-worth{

    text-align: center;
    position: absolute;
    top: 55%;
    left: 18%;
    /*transform: translateY(-50%) translateX(-50%);*/
}

.info-card-fh {
    margin: 10px 0px !important;
    background: white !important;
    border-radius: 10px;

}

.card-info-dsh-fh .PM-card-title {
    color: rgba(0, 0, 0, 0.425) !important;
}

.lblTitulo {
    font-weight: 700;
}

.card-proyeccionesDetalle .lblTitulo{
    width: 100%;
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #A4A4A4;
    margin-bottom: 10px;
}

.statistic-financial.net-worth .ant-statistic-content-value,
.statistic-financial.net-worth .ant-statistic-content-prefix,
.statistic-financial.net-worth .ant-statistic-content-suffix {
    color: white !important;
    font-size: 30px !important;
}




.financial-health .PM-card-dashboard{
    height: 100%;
}
