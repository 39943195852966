.tag-facturas {
    border-radius: 90px !important;
    background-color: #C4C4C4 !important;
}

.react-multi-carousel-item {
    padding-left: 30px !important;
    max-width: 410px !important;
    min-height: 280px !important;
}



.react-multi-carousel-list {
    min-height: 350px !important;
}

.btn-eye{
    background-color: #ADFF45 !important;
    border-color: #ADFF45 !important;
}

.btn-link{
    background-color: #FFE608 !important;
    border-color: #FFE608 !important;
}

.carousel-cards-contabilidad{
    position: relative;
    left: -30px;
    padding-bottom: 30px;
}