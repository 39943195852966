.multiprogress{
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #d4d4d4;
    overflow: hidden;
    border-radius: 20px;

    .progress{
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
    }
}