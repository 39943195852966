.logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
}
.logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
.logo h1 {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  margin: 0 0 0 12px;
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 400;
}
.ant-btn.btn-github {
  position: absolute;
  right: 0px;
  top: 15px;
}
.ant-btn.btn-github img {
  height: 24px;
  margin-right: 8px;
}
.ant-btn.btn-github span {
  display: inline-block;
  vertical-align: bottom;
}
.field-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.field-row .field-name .anticon {
  margin-left: 5px;
}
.header-actions {
  position: absolute;
  top: 0;
  right: 16px;
  float: right;
}
.theme-card-wrapper {
  position: absolute;
  top: 92px;
  right: 28px;
  height: 86%;
}
.theme-edit .preview-header,
.theme-edit .preview {
  padding-right: 380px;
}
.theme-edit .preview {
  max-width: 1600px;
}
.theme-edit .preview .abnormal-hint {
  margin-bottom: 20px;
}
.theme-edit .options {
  margin-top: 10px;
  margin-bottom: 20px;
}
.theme-edit .components.input {
  width: 500px;
}
.theme-edit .components.tree .component-row {
  display: flex;
  justify-content: space-between;
}
.theme-edit .components .component-row {
  margin-bottom: 12px;
}
.theme-edit .components .component-row.calendar {
  width: 300px;
}
.theme-edit .components .component-row .component-col {
  display: inline-block;
  margin-right: 14px;
}
.theme-edit .components .component-row .component-col .badge-square {
  width: 42px;
  height: 42px;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}
.ant-back-top {
  right: 370px !important;
}
