.layout-landing {
  min-height: 100vh;
  background-image: url("../../../../public/img/background/bg-landing.png");
  background-size: cover !important;
  background-position: center !important; /* Center the image */
  background-repeat: no-repeat !important; /* Do not repeat the image */
}

.layout-landing .image-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15%;
  min-height: 62vh;
}

.layout-landing .image-container .btn-erp {
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  max-width: 700px;
  min-height: 60px;
  border-radius: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 19px;
  letter-spacing: 5px;
}

.layout-landing .image-container .logo-container {
  margin-top: 2rem;
}

.layout-landing .image-container .logo-container img {
  position: relative;
  right: 18px;
  height: 200px;
  width: auto;
}