.tag-activo{
    background-color: #F0FFB3 !important;
    border-color: #ADFF00 !important;
    border-width: 2px !important;
    color: #9B9B9B !important;
    width: 100% !important;
    text-align: center !important;
}

.tag-baja{
    background-color: #FFD4DE !important;
    border-color: #FF547D !important;
    border-width: 2px !important;
    color: #9B9B9B !important;
    width: 100% !important;
    text-align: center !important;
}