.public-navbar {
  background: #0047FF;
  height: 116px;
  max-height: 116px;
  width: 100%;
}

.public-navbar .container-logo {
  padding-left: 8rem;
  padding-top: 1.3rem;
}

.public-navbar .container-btn-login {
  padding-right: 6rem;
  padding-top: 2.7rem;
}

/* Cuando la aplicaba al elemento Link no tenia el mismo resultado
que el style, asi que le deje el style */
.public-navbar .container-btn-login .link-login {
  float: right;
}

.public-navbar .container-btn-login .text-login {
  color: white;
  font-family: 'Lato';
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 3px;
}