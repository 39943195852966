@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Roboto:wght@100;300;400;500;700&display=swap');


.layout-login {
    min-height: 100vh;
}

.layout-login .col-welcome {
    padding: 4rem;
    background-image: url("../../../public/img/background/bg-login-stars.png");
    background-size: cover;
}

.layout-login .col-welcome .title-isyt-erp {
    color: black !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    margin-top: 5rem;
}

.layout-login .col-welcome .subtitle-isyt-erp {
    color: black !important;
    font-size: 18px !important;
}

.layout-login .login-form-container {
    padding-right: 5rem;
}

.layout-login .login-form-container .login-form {
    max-width: 400px;
    margin: 17% auto 0 auto;
}

.layout-login .login-form-container .login-form .input-email {
    margin-top: 3rem;
}

.layout-login .link-password-recovery {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 1rem;
    margin-right: 2rem;
    font-weight: 600;
    color: #7A7A9D;
}

.layout-login .password-recovery-form {
    max-width: 550px;
    margin: 25% auto 0 auto;
    padding-right: 2rem;
}

.layout-login .password-recovery-form .input-name {
    margin-top: 3rem;
}

.layout-login .avatar-logged{

    margin-left: auto !important;
    margin-right: auto !important;
}