.layout-content {
  background: #F4F8FB !important;
  max-height: calc(100vh - 100px) !important;
  height: calc(100vh - 100px) !important;
  min-height: calc(100vh - 100px) !important;
}

/*
 * @component Layout -> Content
 * @description Asigna el tamaño del content, se utiliza el calculo de toda la pantalla menos el tamaño del header, menos el tamaño del pageHeader
 */
 .admin-content {
  min-height: 800px !important;
}

/*
 * @component ProjectManager -> Content
 * @description background color del content de la vista de project manager
 */
 .content-bg {
  background: #F4F8FB;
}

/*
 * @component Header 
 * @description Cambia el color del Header y la fuente
 */
 .ant-layout-header {
  color: #FFFFFF;
  line-height: 64px;
  background: #0047FF !important;
}

/*
 * @component PageHeader 
 * @description Cambia el color del PageHeader y la fuente, 
 * se esta sobreescribiendo la clase nativa ya que
 * no hay variables para modificarlo
 */
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 16px 24px;
  background-color: #0047FF !important;
}

/*
 * @component PageHeader 
 * @description Cambia  la fuente del titulo del PageHeader, 
 * se esta sobreescribiendo la clase nativa ya que
 * no hay variables para modificarlo
 */

 .ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 3px;
  color: #FFFFFF !important;
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*
 * @component PageHeader -> Breadcrumb 
 * @description Cambia el texto a un color legible
 */
.breadcrumb-header *{
  color: #FFFFFF !important;
}

.breadcrumb-header .ant-breadcrumb-link, .breadcrumb-header .ant-breadcrumb-separator{
  font-size: 20px !important;
} 

/*
 * @component List->Header 
 * @description Cambia el texto a mayusculas del header de la lista
 */
 .header-list {
  text-transform: uppercase !important;
 
}

/*
 * @component Tag 
 * @description Estilos del tag de Actualiza Saldo del modal de transacciones
 */
 .tag-actualiza-saldo{
  font-size: 12px;
  height: 20px!important;
  width: 98px;
  margin-bottom: 7px!important;
  display: block!important;
  line-height: 20px!important;
}

.ant-menu-title-content{
  margin: 0 !important;
}

.ant-menu-title-content a{
  color: #4D4D4D !important;
}

.ant-menu-sub.ant-menu-inline{
  background: #fdfdfd !important;
}

.ant-menu-item-selected .ant-menu-title-content,
.ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-item-selected svg,
.ant-menu-item-selected .ant-menu-title-content a{
  color: #2C77F9 !important;
  fill: #2C77F9 !important;
}

.tag-activo{
  background-color: #F0FFB3;
  border-color: #ADFF00;
  border-width: 2px;
  color: #9B9B9B;
  width: 100%;
  text-align: center;
}

.tag-baja{
  background-color: #FFD4DE;
  border-color: #FF547D;
  border-width: 2px;
  color: #9B9B9B;
  width: 100%;
  text-align: center;
}

.contador{
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  color: white !important;
  font-family: Lato;
  font-style: normal;
}