.requisiciones{

    & > .ant-row{
        margin: 0px !important;
    }

    .ant-collapse{
        border: unset !important;
    }

    .ant-collapse-item{
        border: unset !important;
    }

    &-collapse{
        display: flex !important;
        flex-direction: column !important;
        row-gap: 12px;
        background-color: transparent !important;
    }

    &-item{

        &-requerido{
            background-color: #F7E5FE;
        }

        &-cotizado{
            background-color: #FCFDC9;
        }

        &-en_pago{
            background-color: #fddbc9;
        }

        &-pausado{
            background-color: #FFDCBC;
        }
        
        &-cancelado{
            background-color: #FFD0D0;
        }
        
        &-entrega{
            background-color: #DAC3FF;
        }

        &-pendiente_entrega{
            background-color: #c3dbff;
        }

        &-recoleccion{
            background-color: #FFCCF1;
        }

        &-entregado{
            background-color: #D9FFE6;
        }
        
    }

    &-tab{
        border-bottom: none !important;

        & .ant-collapse-content-box{
            padding: 4px 0 4px 0 !important;
        }

        & > .ant-collapse-header{
            width: 200px;
            display: inline-flex;
            border-radius: 10px !important;

            & * {
                color: white !important;
            }
        }
        
        &.ant-collapse-item-active > .ant-collapse-header{
            border-radius: 10px 10px 0 0 !important;
        }

        &.tab-cancelado > .ant-collapse-header{
            background-color: #F50606;
        }
        &.tab-requerido > .ant-collapse-header{
            background-color: #B706F5;
        }
        &.tab-cotizado > .ant-collapse-header{
            background-color: #EFCA04;
        }
        &.tab-en_pago > .ant-collapse-header{
            background-color: #96450e;
        }
        &.tab-pausado > .ant-collapse-header{
            background-color: #F57906;
        }
        &.tab-entrega > .ant-collapse-header{
            background-color: #6106F5;
        }
        &.tab-pendiente_entrega > .ant-collapse-header{
            background-color: #0634f5;
        }
        &.tab-recoleccion > .ant-collapse-header{
            background-color: #F506B2;
        }
        &.tab-entregado > .ant-collapse-header{
            background-color: #08E453;
        }
    }

    &-tabname{
        color: white !important;
    }

    &-list.component-list .component-list-item{
        padding: 2px 0 !important;

        &:first-child{
            padding-top: 0px !important;
        }
    }

    &-circle{
        width: 16px;
        height: 16px;
        border-radius: 10px;

        &.small{
            width: 10px;
            height: 10px;
        }

        &.circle-1{
            background-color: #FF4D4F;
        }
        &.circle-2{
            background-color: #FFE608;
        }
        &.circle-3{
            background-color: #5465FF;
        }
        &.circle-4{
            background-color: #F2802E;
        }
        &.circle-5{
            background-color: #00CD98;
        }
    }

    &-dropdown{
        cursor: pointer !important;
    }

    &-select{
        & .ant-select-selection-item, & .ant-select-selection-item > .ant-space, & .ant-select-selection-item > .ant-space > .ant-space-item{
            line-height: 0px !important;
        }
    }

    .text-grey {
        color: #B9BAB9 !important;
    }


    .form-requisicion-opcion{
        width: 100%;
        margin: 0 8px;

        .ant-form-item{
            margin: 0;
            width: 100%;

            .ant-input-number-affix-wrapper,
            .ant-input-number{
                width: 100%;
            }
        }
    }
}
.btn-permission {
    padding: 0 !important;
    border:  none !important;
    cursor: pointer;
}
.btn-permission:hover {
    cursor: pointer;
}

.btn-permission svg{
    margin: 0 !important;
    color: #c6c6c6;
}

.btn-permission.active svg{
    margin: 0 !important;
    color: #01ccfe;
}