@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Poppins:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap');




/*
 * @component Card
 * @description Agrega una sombra al card
 */
.card-shadow {
    background: #FFFFFF;
    border-radius: 10px !important;
    box-shadow: 0px 15px 30px rgba(32, 56, 85, 0.10);
}

.card-head-borderless .ant-card-head {
    border-bottom: 0;
}

.card-custom-title {
    border: none;
    font-family: Poppins;
    font-style: normal;
    line-height: 24px !important;
    font-weight: 400 !important;
    color: #1A3C40 !important;
    margin-bottom: 0;
}

.stadistic-fw-800 {
    font-weight: 700 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 28px;
    line-height: 42px;
    color: #1A3C40;


}


.card-icono-montos {
    .ant-card-body {
        padding: 0px 1.5rem 5px 1.5rem;
    }
}

// Card de historico para el modulo de CashFlow
.card-graph-dashboard {
    .ant-card-head-title {
        border-bottom: none !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        padding: 0;
    }
    .ant-card-head {
        border-bottom: none !important;
    }
    &-year{
        font-size: 16px;
    }
}

/*
*Card para el modulo de transacciones programadas
*/
.card-list-montos {

    .ant-card-body {
        max-height: 325px;
        min-height: 325px;
        overflow-y: auto;
    }

    .ant-card-head-title {
        border-bottom: none !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        padding: 0;
    }

    .ant-card-extra * {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #8C9D9F;

    }

    .ant-card-body {
        padding: 0 10px;
    }

    .list-border-none ul li {
        border-bottom: none !important;
    }

    .list-item-monto {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #F83333;
    }

    .ant-list-header {
        border-bottom: none !important;
    }


}

/*
 * @component CardSaldosGraph
 * @description Borde del card
 */

.card-saldos-dashboard {
    border-radius: 10px !important;

    
}

/*
 * @description Elimina el padding para que el contenido se ajuste mejor
 */

.card-saldos-dashboard .ant-card-body {
    padding: 16px 24px 24px 24px;
}

/*
 * @description Estilos de la fuente del HEAD del card
 */

.card-dashboard .ant-card-head {
    border: none;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #858997;
}

/*
 * @component CardOperations
 * @description Asigna el fondo transparente
 */

.card-venta-mensual-dashboard {
    background: transparent !important;
}

/*
 * @description Estilos del Head del Card
 */
.card-venta-mensual-dashboard .ant-card-head {
    border: none;
    padding: 0 1rem;
    min-height: 15px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.009em;
    color: #262631;
}

/*
 * @description Estilos del Head del Card
 */
.card-venta-mensual-dashboard .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
    padding: 0 !important
}

/*
 * @description Modificacion al padding del Body del Card
 */
.card-venta-mensual-dashboard .ant-card-body {
    padding: 0 1rem;
}

/*
 * @description Modificacion al padding del Meta del Card
 */
.card-venta-mensual-dashboard .ant-card-body .ant-card-meta {
    padding: 1rem 0;
}

/*
 * @description Estilos de fuente del Meta title del Card
 */
.card-venta-mensual-dashboard .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-title {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    color: #2E2E3A;
}

/*
 * @description Estilos de fuente del Meta descripcion del Card
 */
.card-venta-mensual-dashboard .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-description {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #656575;
}

/*
 * @description Estilos del texto de porcentaje e icono  rojos
 */
.ventas-down,
.ventas-down svg {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.009em;
    color: #FD4438;
}

/*
 * @description Estilos del texto de porcentaje e icono verdes
 */
.ventas-up,
.ventas-up svg {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.009em;
    color: #24CA49;
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo {
    border-radius: 10px !important;
    min-height: 219px;
    margin-top: 10px;
}

.card-saldo .card-saldo-header{
    margin-bottom: 1.2rem;
}

/*
 * @component CardSaldo
 * @description el titulo del Card
 */
.card-saldo .card-saldo-title {
    color: #858997;
    font-weight: 600;
    font-size: 1.375rem;
    margin: 0;
}

.card-saldo .card-saldo-label {
    color: #858997;
    font-size: 16px;
    margin: 0 0 5px 0;
}

/*
 * @component CardSaldo
 * @description estilos para el monto total de las cuentas
 */
.card-saldo .card-saldo-monto, .card-saldo .card-saldo-cuentas{
    font-weight: bold;
    font-size: 20px;
}

/*
 * @component CardSaldo
 * @description estilos del small
 */
.card-saldo .card-saldo-main-meta small {
    font-size: 14px;
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo .card-saldo-sub-meta .ant-card-meta-title {
    font-weight: 600;
    font-size: 12px;
    color: #858997;
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo .card-saldo-sub-meta .ant-card-meta-description {
    font-weight: 600;
    font-size: 12px;
    color: #0047FF !important;
    margin-bottom: 20px;
}

/*
 * @component CardCuenta
 * @description limita el tamaño de cada contenedor principal del carrousel
 */
.carousel-cards.react-multi-carousel-list {
    min-height: auto !important;
}


/*
 * @component CardCuenta
 * @description limita el tamaño de cada contenedor principal del carrousel
 */
.carousel-cards .react-multi-carousel-item {
    max-width: 390px;
    min-height: auto !important;
}

/*
 * @component CardCuenta
 * @description Estilo para la tarjeta bancaria
 */
.card-cuenta {
    border-radius: 20px !important;
    margin-right: 1rem !important;
    min-height: 219px;
    width: 370px;
    margin-bottom: 5px !important;
}

.card-cuenta .btn-purple {
    background: rgba(169, 6, 245, 0.75);
    color: white;
    border: none;
}

/*
 * @component CardCuenta
 * @description Estilo para el nombre de la cuenta
 */
.card-cuenta .cuenta-nombre {
    font-size: 1.125rem;
    color: #FFFFFF;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo principal de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-title {
    font-weight: bold;
    font-size: 1.875rem;
    color: #FFFFFF;
    margin-top: 0.9rem;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo secundario de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-description {
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 0.5rem;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo secundario de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-title small {
    font-size: 14px;
}

/*
 * @component CardGains
 * @description Estilo para los cards de profit y loss
 */
.card-gains {
    font-family: Inter;
    font-style: normal;
    max-height: 250px;
    width: 100%;
    min-width: 250px;
    border-radius: 16px !important;
}

.card-gains .ant-card-head {
    border-bottom: none !important;
}

/*
 * @component CardGains
 * @description Estilo para el titulo del card
 */
.card-gains .ant-card-head .ant-card-head-title .card-title {
    font-weight: 600;
    font-size: 16px;
    color: #27272E;

}

/*
 * @component CardGains
 * @description Estilo para los montos del card
 */
.card-gains .text-paid {
    font-weight: normal;
    font-size: 14px !important;
    color: #84859E;
    text-transform: capitalize;
}

/*
 * @component CardGains
 * @description Estilo para los montos del card
 */
.card-gains .dots {
    padding-right: 5px;
}

/*
 * @component CardGains
 * @description Estilo para los montos del card
 */
.card-gains .dots svg {
    transform: rotate(90deg);
}

/*
 * @component CardGains
 * @description Estilo para el icon del card
 */
.card-gains .green-box {
    background: rgba(29, 210, 0, 0.1);
    height: 48px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

/*
 * @component CardGains
 * @description Estilo para el icon del card
 */
.card-gains .red-box {
    background: rgba(229, 10, 10, 0.1);
    height: 48px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}

/*
 * @component CardGains
 * @description Estilo para el icon del card
 */
.card-gains .red-box svg,
.card-gains .green-box svg {
    width: 25px;
}



@media screen and (max-height:1024px) {

    .green-box svg,
    .red-box svg {
        width: 20px !important;
    }

    .green-box,
    .red-box {
        height: 38px !important;
        width: 40px !important;
    }


}

@media screen and (max-width:1024px) {
    .card-list-montos {
        .ant-list-item-meta-title * {
            font-size: 12px !important;
        }
    }
}

@media screen and (min-width:1200px) {
    .card-list-montos {
        .ant-list-item-meta-title * {
            font-size: 14px !important;
        }
    }
}


.card-empresa {

    max-width: 400px;
    min-height: 280px;

    .empresa-nombre {
        font-size: 15px;
        font-weight: 500;
        line-height: 27px;
        color: #121212;
    }

    .factura-tag {
        background-color: #C4C4C4;
        border-radius: 100px;

        .texto {

            color: #FFFFFF;

        }
    }

    .alta-fecha {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        color: #C4CAD3;
    }

    .card-title {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.5px;
        color: #2D348C;

    }

    .cantidad-titulo {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0.30000001192092896px;
        color: #678BAA;

    }

    .cantidad {
        font-family: Poppins;
        font-size: 15px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.5px;
        color: #000000;

    }

}